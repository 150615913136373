import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import _ from 'lodash';
import React from 'react';
import { Chart, ChartLegend, ChartsByGroup, GroupChart } from '../../types/ChartsComponents';
import BarChartContainer from '../charts/BarChartContainer';
import BarChartProductContainer from '../charts/BarChartProductContainer';
import GaugeChartContainer from '../charts/GaugeChartContainer';
import HeatmapChartContainer from '../charts/HeatmapChartContainer';
import PieChartContainer from '../charts/PieChartContainer';
import SankeyChartContainer from '../charts/SankeyChartContainer';
import GroupChartContainer from './GroupChartContainer';
import { t } from 'i18next';
import { BarChart } from '@ComponentsRoot/ChartsComponents/types/BarChartTypes';
import { GaugeChart } from '@ComponentsRoot/ChartsComponents/types/GaugeChartTypes';
import { HeatMapChart } from '@ComponentsRoot/ChartsComponents/types/HeatMapChartTypes';
import { PieChart } from '@ComponentsRoot/ChartsComponents/types/PieChartTypes';
import {
  GroupChartContainerProps,
  GroupGeneralSitesChartContainerState,
} from '@ComponentsRoot/ChartsComponents/types/GroupChartTypes';

class GroupGeneralSitesChartContainer extends PureComponent<GroupChartContainerProps, GroupGeneralSitesChartContainerState> {
  static contextType = AuthenticationContext;
  legendRef: React.RefObject<HTMLDivElement>;
  constructor(props: GroupChartContainerProps) {
    super(props);
    this.state = {
      generalLegend: undefined,
      legend: undefined,
      language: '',
      isLegendOverflowing: false,
    };
    this.legendRef = React.createRef<HTMLDivElement>();
  }

  componentDidUpdate() {
    const container = this.legendRef.current;
    if (container) {
      const isOverflowing = container.scrollWidth > container.clientWidth;
      if (isOverflowing !== this.state.isLegendOverflowing) {
        this.setState({ isLegendOverflowing: isOverflowing });
      }
    }
  }

  getLegendFromChart = (legend: ChartLegend) => {
    // get legend from chart and add to state
    if (!this.state.generalLegend) {
      this.setState({ generalLegend: legend });
    } else {
      const generalLegend = _.merge({}, this.state.generalLegend, legend);
      this.setState({ generalLegend });
    }
  };

  getChartComponentByType = (chart: Chart) => {
    const childrenProps = {
      chart,
      chartRepository: this.props.chartRepository,
      legendFilter: this.state.legend,
      dataPoints: chart.dataPoints,
      language: this.state.language,
      getLegendFromChart: this.getLegendFromChart.bind(this),
      generalLegend: this.state.generalLegend,
    };
    switch (chart.chartType) {
      case 'pie':
        return <PieChartContainer {...childrenProps} chart={chart as PieChart} />;
      case 'gauge':
        return <GaugeChartContainer {...childrenProps} chart={chart as GaugeChart} />;
      case 'bar_echarts':
        if (this.props.page === 'product') {
          return <BarChartProductContainer {...childrenProps} chart={chart as BarChart} />;
        } else {
          return <BarChartContainer {...childrenProps} chart={chart as BarChart} />;
        }
      case 'sankey':
        return <SankeyChartContainer {...childrenProps} />;
      case 'heatmap_echarts':
        return <HeatmapChartContainer {...childrenProps} chart={chart as HeatMapChart} />;
      default:
        <p>{`Tipo de gráfico no soportado: ${chart.chartType}`}</p>;
        break;
    }
  };

  renderKPIsCharts = (group: Chart[]) => {
    return group.map((chart: Chart, key: number) => (
      <React.Fragment key={key}>{this.getChartComponentByType(chart)}</React.Fragment>
    ));
  };

  renderLegend = () => {
    const generalLegend = this.state.generalLegend as ChartLegend;
    if (!Object.keys(generalLegend).length) return null;

    const sortedLegend = Object.keys(generalLegend).sort((a, b) => a.localeCompare(b));

    return (
      <div ref={this.legendRef} className={`general-site-legend ${this.state.isLegendOverflowing ? 'desb' : ''}`}>
        {sortedLegend.map((itemName, key) => {
          const item = generalLegend[itemName];
          return (
            <div className={`child ${!item.status && 'notselected'}`} key={key} onClick={() => this.legendHandler(itemName)}>
              <div className='color' style={{ backgroundColor: item.color }}></div>
              <span className='t2'>{itemName}</span>
            </div>
          );
        })}
      </div>
    );
  };

  legendHandler = (itemName: string) => {
    const generalLegend = this.state.generalLegend as ChartLegend;
    const newLegend = {
      ...generalLegend,
      [itemName]: { ...generalLegend[itemName], status: !generalLegend[itemName].status },
    };
    this.setState({ generalLegend: newLegend });
  };

  renderGroups = () => {
    const groupChartArray: React.ReactNode[] = [];

    // All groups are iterated
    Object.values(this.props.groups as ChartsByGroup).forEach((group: GroupChart) => {
      const groupTitle = t('charts:titles.' + group.charts[0].title);

      if (group.charts.length) {
        if (group.charts[0].chartType !== 'lastvalue' && group.charts.length === 1) {
          groupChartArray.push(this.getChartComponentByType(group.charts[0]));
        } else {
          groupChartArray.push(
            <GroupChartContainer
              chartRepository={this.props.chartRepository}
              group={group.charts}
              legend={this.state.legend as ChartLegend}
              hiddenLegend={true}
              title={groupTitle}
              page={this.props.page}
              getLegendFromChart={this.getLegendFromChart.bind(this)}
              generalLegend={this.state.generalLegend as ChartLegend}
            />
          );
        }
      }
    });
    return groupChartArray;
  };

  render() {
    return (
      <>
        {this.props.groups ? (
          <div className='chart-group general'>
            <div className='title header-chart-group'>{this.state.generalLegend ? this.renderLegend() : null}</div>
            <div className='charts-box'>{this.renderGroups()}</div>
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default GroupGeneralSitesChartContainer;
