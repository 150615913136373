import SiteChartsHttpClient from '../../../HttpClient/SiteChartsHttpClient';
import ChartHttpClient from '../../../HttpClient/ChartHttpClient';
import ProductHttpClient from '../../../HttpClient/ProductHttpClient';
import InstallationChartsHttpClient from '../../../HttpClient/InstallationChartsHttpClient';
import HeatMapHttpClient from '../../../HttpClient/HeatMapHttpClient';
import ProductChartsHttpClient from '../../../HttpClient/ProductChartsHttpClient';
import type { KPIsChartContainer } from '../container/KPIsChartContainer';
import moment from 'moment';
import { hexToHSL, normalizeWeekday } from './chartComponentsUtils';
import { Chart, ChartTimeStamp, ReferenceChart } from '../types/ChartsComponents';
import { BarChartDataPoint } from '../types/BarChartTypes';
import { PieChartDataPoint } from '../types/PieChartTypes';
import { GaugeCharDataPoint } from '../types/GaugeChartTypes';
import { HeatMapChartDataPoint } from '../types/HeatMapChartTypes';
class chartRepository {
  kpisChartContainer: KPIsChartContainer;

  constructor(kpisChartContainer: KPIsChartContainer) {
    this.kpisChartContainer = kpisChartContainer;
  }

  manageLegend = (dataPoints: BarChartDataPoint[] | PieChartDataPoint[] | GaugeCharDataPoint[] | HeatMapChartDataPoint[]) => {
    if (this.kpisChartContainer.props.page === 'product') {
      return null;
    }
    const newLegend = {};
    dataPoints.map((dataPoint) => {
      const item = { name: dataPoint.name, color: hexToHSL(dataPoint.color, false) };
      if (!(item.name in newLegend)) {
        newLegend[item.name] = {
          status: true,
          color: item.color,
        };
      }
    });
    return newLegend;
  };

  manageChartType = async (chart: Chart) => {
    if (chart.chartType === 'sankey') {
      return await this.getSankeyTimeSeries(chart);
    } else {
      switch (chart.page) {
        case 'site':
          return await this.manageSiteChartType(chart);
        case 'installation':
          return await this.manageInstallationChartType(chart);
        case 'product':
          return await this.manageProductChartType(chart);
        default:
          return false;
      }
    }
  };

  private getTimeStamp = (dateRange: [string, string]) => {
    return {
      start: dateRange && moment(dateRange[0]).format('YYYY-MM-DDT00:00:00'),
      end: dateRange && moment(dateRange[1]).format('YYYY-MM-DDT23:59:59'),
    };
  };

  private getSankeyTimeSeries = async (chart: Chart) => {
    const { state, context } = this.kpisChartContainer;
    const httpSite = new SiteChartsHttpClient(context, state.timeZoneOffset);
    let timeStamp = this.getTimeStamp(chart.dateRange);
    let timeseries = await httpSite.SitesChartsSankey(
      chart.name,
      chart.type,
      chart.brandCode,
      chart.filterByBrandCode,
      timeStamp.start,
      timeStamp.end,
      chart.deviceUnits
    );
    if (timeseries && timeseries.data && timeseries.data.content) {
      return {
        deviceUnits: this.getDeviceUnitsResponse(timeseries),
        content: timeseries.data.content,
      };
    }
  };

  private manageSiteChartType = async (chart: Chart) => {
    const { state, context } = this.kpisChartContainer;
    let timeStamp = this.getTimeStamp(chart.dateRange);
    let httpSite = new SiteChartsHttpClient(context, state.timeZoneOffset);
    try {
      if (chart.chartType === 'gauge' || chart.chartType === 'pie') {
        let timeseries = await httpSite.SiteChartsStatisticalMeasures(
          this.kpisChartContainer.state.id,
          chart.name,
          chart.type,
          chart.aggregateBy,
          timeStamp.start,
          timeStamp.end,
          chart.groupByEcharts,
          chart.filters,
          chart.deviceUnits
        );

        if (timeseries && timeseries.data && timeseries.data.content) {
          return {
            deviceUnits: this.getDeviceUnitsResponse(timeseries),
            content: timeseries.data.content,
          };
        }
      } else {
        let timeseries = await httpSite.SiteChartsDatapoints(
          this.kpisChartContainer.state.id,
          chart.name,
          chart.type,
          chart.groupBy,
          chart.granularity,
          chart.aggregateBy,
          chart.calculation,
          timeStamp.start,
          timeStamp.end,
          chart.chartType,
          chart.filters,
          chart.deviceUnits
        );

        if (timeseries && timeseries.data && timeseries.data.content) {
          return {
            deviceUnits: this.getDeviceUnitsResponse(timeseries),
            content: timeseries.data.content,
          };
        }
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  private manageInstallationChartType = async (chart: Chart) => {
    const { state, context } = this.kpisChartContainer;
    let httpInstallation = new InstallationChartsHttpClient(context, state.timeZoneOffset);
    let timeStamp = this.getTimeStamp(chart.dateRange);
    try {
      if (chart.chartType === 'gauge' || chart.chartType === 'pie') {
        let timeseries = await httpInstallation.InstallationChartsStatisticalMeasures(
          this.kpisChartContainer.state.id,
          chart.name,
          chart.type,
          chart.aggregateBy,
          timeStamp.start,
          timeStamp.end,
          chart.groupByEcharts,
          chart.filters,
          chart.deviceUnits
        );

        if (timeseries && timeseries.data && timeseries.data.content) {
          return {
            deviceUnits: this.getDeviceUnitsResponse(timeseries),
            content: timeseries.data.content,
          };
        }
      } else {
        let timeseries = await httpInstallation.InstallationChartsDatapoints(
          this.kpisChartContainer.state.id,
          chart.name,
          chart.type,
          chart.groupBy,
          chart.granularity,
          chart.aggregateBy,
          chart.calculation,
          timeStamp.start,
          timeStamp.end,
          chart.chartType,
          chart.filters,
          chart.deviceUnits
        );
        if (timeseries && timeseries.data && timeseries.data.content) {
          return {
            deviceUnits: this.getDeviceUnitsResponse(timeseries),
            content: timeseries.data.content,
          };
        }
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  private manageProductChartType = async (chart: Chart) => {
    const { state, context } = this.kpisChartContainer;
    let timeStamp = this.getTimeStamp(chart.dateRange);

    try {
      if (chart.chartType === 'heatmap_echarts') {
        let httpHeatmap = new HeatMapHttpClient(context, state.timeZoneOffset);
        timeStamp.end = moment().add(-1, 'd').format('YYYY-MM-DDT23:59:59');
        timeStamp.start = moment().add(-7, 'd').format('YYYY-MM-DDT00:00:00');

        let response = await httpHeatmap.HeatmapKPIDatapoints(
          chart.name,
          'week',
          'product',
          this.kpisChartContainer.state.id,
          'hours',
          timeStamp.start,
          timeStamp.end,
          chart.deviceUnits
        );
        if (response.data.content === undefined) {
          response.data = [];
          return {
            deviceUnits: this.getDeviceUnitsResponse(response),
            content: response.data,
          };
        } else {
          const heatmapData = this.calculateHeatMapData(response);
          return {
            deviceUnits: this.getDeviceUnitsResponse(response),
            content: heatmapData,
          };
        }
      } else {
        const { state, context } = this.kpisChartContainer;
        let httpProduct = new ProductChartsHttpClient(context, state.timeZoneOffset);
        let timeStamp: ChartTimeStamp = this.getTimeStamp(chart.dateRange);

        if (chart.chartType === 'lastvalue') {
          chart.granularity = 'none';
          chart.groupBy = 'none';
          timeStamp.start = moment().add(-1, 'd').format('YYYY-MM-DDT00:00:00');
          timeStamp.end = moment().format('YYYY-MM-DDT23:59:59');
          if (chart.type === 'telemetry') {
            return {
              content: chart.telemetry,
            };
          }
        }
        let response = await httpProduct.ProductChartsDatapoints(
          this.kpisChartContainer.state.id,
          chart.name,
          chart.type,
          chart.groupBy,
          chart.granularity,
          chart.calculation,
          timeStamp.start,
          timeStamp.end,
          chart.chartType,
          chart.deviceUnits
        );
        if (response && response.data && response.data.content) {
          return {
            deviceUnits: this.getDeviceUnitsResponse(response),
            content: response.data.content,
          };
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  private getDeviceUnitsResponse(response) {
    var units: string = '';
    if (response.headers['x-device-units']) {
      const deviceUnits = JSON.parse(response.headers['x-device-units']);
      const unitKey = Object.keys(deviceUnits)[0];
      const unitValue = Object.values(deviceUnits)[0];
      if (unitKey.toLowerCase() != 'unknown' && unitValue != '') {
        units = (unitValue as String).toString();
      }
    }

    return units;
  }

  private calculateHeatMapData = (heatmap) => {
    let dades = JSON.parse(JSON.stringify(heatmap.data.content));
    let arrayHeatmap: any[] = [];
    let c = 0;
    for (let day of dades) {
      for (let hour of day.data) {
        c++;
        let valorsHour: any[] = [];
        let h = moment.utc(hour.ts).format('HH');
        valorsHour.push(parseInt(h));
        valorsHour.push(normalizeWeekday(moment.utc(hour.ts).weekday()));
        if (hour.data != 0) {
          valorsHour.push(hour.data);
        } else {
          valorsHour.push('-');
        }
        arrayHeatmap.push(valorsHour);
      }
    }

    const arr = Array.from(arrayHeatmap);

    for (let i = 1; i < arr.length; i++) {
      for (let j = 0; j < arr.length - i; j++) {
        if (arr[j][0] > arr[j + 1][0]) {
          [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
        }
      }
    }

    for (let i = 1; i < arr.length; i++) {
      for (let j = 0; j < arr.length - i; j++) {
        if (arr[j][1] > arr[j + 1][1]) {
          [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
        }
      }
    }
    heatmap.data = arr;
    return heatmap;
  };

  getSitesSmartComponentTypeIds = async () => {
    const { context, state } = this.kpisChartContainer;
    let httpSite = new SiteChartsHttpClient(context, state.timeZoneOffset);
    let smartComponentTypeIds = await httpSite.SitesGetSmartComponentTypeIds();
    return smartComponentTypeIds.content;
  };

  getPicto = async (kpiChartName: string, brandCode: string) => {
    const { context } = this.kpisChartContainer;
    let httpChart = new ChartHttpClient(context);
    let picto = await httpChart.PictoByKPI(kpiChartName, brandCode);
    return picto;
  };

  getSystems = async () => {
    const { state, context } = this.kpisChartContainer;
    const httpProduct = new ProductHttpClient(context, state.id);
    let systems = await httpProduct.ProductsGetSystems();
    return systems;
  };

  productChartsKPIDatapoints = async (referenceChart: ReferenceChart) => {
    const { context, state } = this.kpisChartContainer;
    let httpProduct = new ProductChartsHttpClient(context, state.timeZoneOffset);
    let timeStamp = this.getTimeStamp(referenceChart.dateRange);

    let timeseries = await httpProduct.ProductChartsDatapoints(
      this.kpisChartContainer.state.id,
      referenceChart.name,
      referenceChart.type,
      referenceChart.groupBy,
      referenceChart.granularity,
      referenceChart.calculation,
      timeStamp.start,
      timeStamp.end,
      referenceChart.chartType
    );
    return timeseries;
  };

  installationKPIDatapoints = async (chart: Chart) => {
    const { context, state } = this.kpisChartContainer;
    let httpInstallation = new InstallationChartsHttpClient(context, state.timeZoneOffset);
    let timeStamp = this.getTimeStamp(chart.dateRange);

    let timeseries = await httpInstallation.InstallationChartsDatapoints(
      this.kpisChartContainer.state.id,
      chart.name,
      chart.type,
      chart.groupBy,
      chart.granularity,
      chart.aggregateBy,
      chart.calculation,
      timeStamp.start,
      timeStamp.end,
      chart.chartType,
      chart.filters
    );
    return timeseries;
  };
}

export default chartRepository;
