import { PureComponent } from 'react';
import AuthenticationContext, { AuthContextType } from '../../../../Authentication/types/AuthContextType';
import { t } from 'i18next';
import _ from 'lodash';
import { applyDecimals } from '../../utilsCharts';
import moment from 'moment';
import EmptyLastValueChartView from '../../views/EmptyLastValueChartView';
import LastValueSkeleton from '../../skeleton/LastValueSkeleton';
import {
  KpiItem,
  LastValueChartContainerProps,
  LastValueChartContainerState,
  LastValueChartDataPoint,
  responseLastValueChartDataPoint,
  responsePictoData,
} from '@ComponentsRoot/ChartsComponents/types/LastValueTypes';

class LastValueChartContainer extends PureComponent<LastValueChartContainerProps, LastValueChartContainerState> {
  static contextType = AuthenticationContext;
  constructor(props: LastValueChartContainerProps) {
    super(props);
    this.state = {
      dataLoaded: false,
      arr_kpis: [],
      icon: false,
      error: false,
      dataPoints: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.chart && !this.state.dataLoaded) {
      await this.getDataPoints();
    }
  };

  units;
  getDataPoints = async () => {
    try {
      const response = (await this.props.chartRepository.manageChartType(this.props.chart)) as responseLastValueChartDataPoint;
      const dataPoints = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : this.props.chart.units;
      if (!dataPoints) {
        // this.notifyGroupChartFinished(this.props.chart.name, false);
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        return null;
      } else if (!(dataPoints as LastValueChartDataPoint[]).length) {
        // this.notifyGroupChartFinished(this.props.chart.name, false);
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        return null;
      }

      const arr_kpis: KpiItem[] = [];
      const { type, telemetry, name, id } = this.props.chart;
      let valueTelemetry: any = null;
      let icon = '';
      const context = this.props.context as AuthContextType;
      if (type === 'telemetry' && telemetry.data?.content) {
        icon =
          'https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/' +
          context.brandCode +
          '_' +
          this.props.chart.icon +
          '.svg';

        valueTelemetry = this.getTelemetry(telemetry.data.content, name, id);
        if (valueTelemetry) {
          const kpi = {
            value: valueTelemetry.value,
            lastProcessed: valueTelemetry.lastProcessed,
            tsISO: valueTelemetry.tsISO,
            key: name,
            name: '',
          };
          arr_kpis.push(kpi);
        }
      } else if (type == 'kpi') {
        let responsePicto = (await this.props.chartRepository.getPicto(name, context.brandCode as string)) as responsePictoData;
        if (responsePicto.status == 200) {
          icon = responsePicto.data.content.url;
        } else {
          icon =
            'https://strgpiotcoredev.blob.core.windows.net/pictos-by-kpi/' +
            context.brandCode +
            '_' +
            this.props.chart.icon +
            '.svg';
        }
        if ((dataPoints as LastValueChartDataPoint[]).length) {
          (dataPoints as LastValueChartDataPoint[]).forEach((dataPoint) => {
            if (dataPoint.key && dataPoint.value !== undefined) {
              const kpi = {
                value: dataPoint.value,
                key: dataPoint.key,
                name: dataPoint.name,
                tag: dataPoint.tag,
              };
              arr_kpis.push(kpi);
            }
          });
        } else if ((dataPoints as LastValueChartDataPoint).key && (dataPoints as LastValueChartDataPoint).value !== undefined) {
          const kpi = {
            value: (dataPoints as LastValueChartDataPoint).value,
            key: (dataPoints as LastValueChartDataPoint).key,
            name: '',
            tag: '',
          };
          arr_kpis.push(kpi);
        }
      }

      if (arr_kpis.length) {
        const arr_kpis_rounded: KpiItem[] = [];
        arr_kpis.forEach((kpi, key) => {
          arr_kpis_rounded.push({ ...arr_kpis[key], value: applyDecimals(kpi.value, this.props.chart.decimals) });
        });
        icon = icon + '?t=' + moment().unix(); //to refresh icon

        // this.notifyGroupChartFinished(this.props.chart.name, true);
        this.setState({
          dataLoaded: true,
          arr_kpis: arr_kpis_rounded,
          icon,
          dataPoints: dataPoints as LastValueChartDataPoint[],
        });
      } else {
        // this.notifyGroupChartFinished(this.props.chart.name, false);
        this.setState({
          dataLoaded: true,
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
      }
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
    }
  };

  getTelemetry(telemetry, name, id) {
    let t = telemetry; //content
    let res = null;
    if (t[name] != undefined && t[name] != null) {
      res = t[name];
    } else {
      if (t != null && t != undefined) {
        try {
          res = t.find((v) => v.key == name && v.id == id);
          if (res == undefined) {
            res = t.find((v) => v.key == name);
          }
        } catch (err) {
          // Is not updated to new list yet
        }
      }
    }
    return res;
  }

  renderLastValues = () => {
    return this.state.arr_kpis.map((kpi: KpiItem, key: number) => (
      <div key={key} style={{ padding: '10px 5px', marginLeft: 0, width: '200px' }} className='prodChildNoZoom'>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: '36px', lineHeight: '36px', textAlign: 'center' }}>
            <h4 className='kpiTitle mb-1' style={{ display: 'inline-block', verticalAlign: 'middle' }}>
              {t('charts:titles.' + this.props.chart.title)}
            </h4>
          </div>
          <div className='kpiValueAc t2' style={{ flexGrow: 1 }}>
            {kpi.name}
          </div>
          <img className='kpiIcon ' src={this.state.icon as string} />
          <h1 style={{ textAlign: 'center', padding: '5px 0' }}>{kpi.value}</h1>
          <div className='kpiValueAc t2'>{t('charts:axisTitles.' + this.units.toLowerCase())}</div>
        </div>
      </div>
    ));
  };

  render() {
    return (
      <>
        {this.state.error ? (
          <EmptyLastValueChartView error={this.state.error} title={t('charts:titles.' + this.props.chart.title)} />
        ) : this.state.dataLoaded ? (
          this.renderLastValues()
        ) : !this.state.dataPoints || !this.state.dataPoints.length ? (
          // <Skeleton animation='wave' variant='rectangular' width={200} height={205} />
          <LastValueSkeleton></LastValueSkeleton>
        ) : (
          ''
        )}
      </>
    );
  }
}

export default LastValueChartContainer;
