import moment from 'moment';
import { Chart, ChartLegend, Filter, KPIsChartContainerProps } from '../types/ChartsComponents';
import _ from 'lodash';
import { ChartContainerProps, ChartOptions } from '../types/BarChartTypes';

export const compareGeneralLegend = (propsGeneralLegend: ChartLegend, StateChartLegend: ChartLegend) => {
  const generalLegend = _.cloneDeep(propsGeneralLegend);
  const chartLegend = _.cloneDeep(StateChartLegend);
  const updatedChartLegend = {};

  // Iterate over the keys of chartLegend
  _.keys(chartLegend).forEach((key) => {
    // Check if the key exists in generalLegend and if there's a difference in status
    if (generalLegend[key] && generalLegend[key].status !== chartLegend[key].status) {
      // If there's a difference, update the value in updatedChartLegend
      updatedChartLegend[key] = {
        ...chartLegend[key],
        status: generalLegend[key].status,
      };
    } else {
      // If there's no difference, keep the original value in updatedChartLegend
      updatedChartLegend[key] = chartLegend[key];
    }
  });
  // Check if there are differences
  if (!_.isEqual(chartLegend, updatedChartLegend)) {
    // Return the updated legend
    return updatedChartLegend;
  }
  return false;
};

export const calculateDates = (chart: Chart, dateRange: string, groupByEcharts: string) => {
  let chartTypes = ['line', 'bar', 'gauge', 'pie', 'line_echarts', 'bar_echarts', 'sankey'];
  let groupBy = chart.groupBy;
  let granularity = chart.granularity;
  let calculation = chart.calculation;
  let name = chart.name;
  const endRange = moment(dateRange[1]);
  const beginRange = moment(dateRange[0]);
  const daysDiff = endRange.diff(beginRange, 'days');

  //check if the diference between range > 3 month or 90 days, and show information group by month
  if (daysDiff > 90) {
    groupBy = 'month';
    granularity = 'month';
    calculation = 'sum';
    if ((!name.includes('Statistic') || name.includes('Calculated')) && chartTypes.includes(chart.chart as string))
      name = name.replace('PerHour', 'PerDay');
  } else if (daysDiff < 5) {
    groupBy = 'hour';
    granularity = 'hour';
    calculation = 'sum';
    if ((!name.includes('Statistic') || name.includes('Calculated')) && chartTypes.includes(chart.chart as string))
      name = name.replace('PerDay', 'PerHour');
  } else {
    switch (groupByEcharts) {
      case 'year':
        groupBy = 'month';
        granularity = 'month';
        calculation = 'sum';
        if ((!name.includes('Statistic') || name.includes('Calculated')) && chartTypes.includes(chart.chart as string))
          name = name.replace('PerHour', 'PerDay');
        break;
      case 'week':
      case 'custom':
      case 'month':
        groupBy = 'day';
        granularity = 'day';
        if ((!name.includes('Statistic') || name.includes('Calculated')) && chartTypes.includes(chart.chart as string))
          name = name.replace('PerDay', 'PerHour');
        break;
      case 'monthWeek':
        groupBy = 'week';
        granularity = 'week';
        calculation = 'sum';
        if ((!name.includes('Statistic') || name.includes('Calculated')) && chartTypes.includes(chart.chart as string))
          name = name.replace('PerHour', 'PerDay');
        break;
      case 'day':
      default:
        groupBy = 'hour';
        granularity = 'hour';
        if ((!name.includes('Statistic') || name.includes('Calculated')) && chartTypes.includes(chart.chart as string))
          name = name.replace('PerDay', 'PerHour');
        break;
    }
  }
  return { name, groupBy, granularity, calculation, chart };
};

export const configurateOptions = (options: ChartOptions, chart: Chart) => {
  let xaxisTitle = chart.xaxisTitle,
    yaxisTitle = chart.yaxisTitle;

  switch (chart.groupBy) {
    case 'week':
      xaxisTitle = 'Weeks';
      break;
    case 'month':
      xaxisTitle = 'Months';
      break;
    case 'hour':
      xaxisTitle = 'Hours';
      break;
    case 'day':
    default:
      xaxisTitle = 'Days';
      break;
  }

  //config witdh & height
  if (chart.library === 'echarts') {
    if (chart.height && chart.height > 0 && chart.width && chart.width > 0 && options?.series?.length) {
      options.series[0].height = chart.height + 'px';
      options.series[0].width = chart.width + 'px';
    }
  } else {
    if (chart.height && chart.height > 0 && chart.width && chart.width > 0) {
      options.chart.height = chart.height;
      options.chart.width = chart.width;
    }

    if (chart.chart !== 'lastvalue') {
      options.xaxis!.title.text = xaxisTitle;
      options.yaxis![0].title.text = yaxisTitle;
    }
  }
  return options;
};

export const mapperStackedFromChart = (
  stacked,
  chart: Chart,
  propsChartContainer: KPIsChartContainerProps,
  calcGroupBy: string,
  calcGranularity: string
) => {
  let chartType = chart.chart;
  let type = chart.type;
  let library = chart.library;
  let units = chart.units;
  let granularity = calcGranularity;
  let groupBy = calcGroupBy;
  let calculation = chart.calculation;
  let title = stacked.title;
  let color = stacked.color != undefined ? stacked.color : chart.color;
  let id = stacked.id;
  let groupByEcharts = propsChartContainer.groupBySelected;
  let aggregateBy = propsChartContainer.aggregateBy;
  let filters = setFilter(propsChartContainer.filter, propsChartContainer.gender);
  let page = propsChartContainer.page;
  let dateRange = propsChartContainer.dateRange;
  let name = stacked.name;
  let kpiReference = stacked.kpiReference;
  if (chart.name.includes('PerDay')) {
    name = name.replace('PerHour', 'PerDay');
  } else if (chart.name.includes('PerHour')) {
    name = name.replace('PerDay', 'PerHour');
  }
  return {
    chartType,
    type,
    library,
    units,
    granularity,
    groupBy,
    calculation,
    title,
    color,
    id,
    groupByEcharts,
    aggregateBy,
    filters,
    page,
    dateRange,
    name,
    kpiReference,
  };
};

export const getColor = (hex?: string) => {
  let hexColor = '#E63946';
  if (hex) {
    hexColor = hex;
  }
  const hslColor = hexToHSL(hexColor, false);
  const hslColorDarker = hexToHSL(hexColor, true);
  const hslBackGroundColor = hexToHSL(hexColor, true);
  return {
    color: hslColor,
    borderColor: hslColorDarker,
    backgroundColor: hslBackGroundColor,
  };
};

export const hexToHSL = (hex: string, darker = true) => {
  let colorInHSL;
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);

    r = r / 255;
    g = g / 255;
    b = b / 255;

    let max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max == min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    if (darker == true) {
      colorInHSL = 'hsl(' + h + ', ' + s + '%, ' + '40%)';
    } else {
      colorInHSL = 'hsl(' + h + ', ' + s + '%, ' + '75%)';
    }
  }
  return colorInHSL;
};

export const getIdFromUrl = (): number => {
  const pathSegments = window.location.pathname.split('/');
  const idIndex = 2;
  const id = parseInt(pathSegments[idIndex], 10);
  const isValidId = !isNaN(id) && Number.isInteger(id);
  return isValidId ? id : 2;
};

export const setFilter = (filter: Filter | undefined, gender?: string) => {
  return {
    gender: gender ?? [],
    type: filter?.type ?? [],
    area: filter?.area ?? [],
    environment: filter?.environment ?? [],
    productTypeId: filter?.productTypesSelected ?? [],
    productId: filter?.productsSelected ?? [],
    familyId: filter?.familiesSelected ?? [],
  };
};

export const sumArrays = (chartDataLength: number, arrays: number[][]) => {
  return arrays.length == 0
    ? Array.from({ length: chartDataLength }, (_, i) => 0)
    : arrays.reduce((acc, array) => acc.map((sum, i) => sum + array[i]), new Array(arrays[0].length).fill(0));
};

export const calculatePercentage = (value: number, elements?: { value: number }[]) => {
  if (elements != undefined && value >= 0) {
    let percentage = (value / elements.reduce((a, b) => a + b.value, 0)) * 100;
    if (isNaN(percentage)) percentage = 0;
    return percentage;
  } else return 0;
};

export const sortByValueSankey = (arr: { links: { value: number }[] }) => {
  arr.links = arr.links.sort((a, b) => (a.value < b.value ? 1 : -1));
  return arr.links;
};

export const hierarchySortSankey = (arr) => {
  const hierarchySortFunc = (a, b) => {
    return a.target > b.target;
  };

  const hierarhySort = (hashArr, key, result) => {
    if (hashArr[key] == undefined) return;
    let arraySort = hashArr[key].sort(hierarchySortFunc);
    for (let i = 0; i < arraySort.length; i++) {
      result.push(arraySort[i]);
      hierarhySort(hashArr, arraySort[i].target, result);
    }
    return result;
  };

  let hashArr = {};

  for (let i = 0; i < arr.links.length; i++) {
    if (hashArr[arr.links[i].source] == undefined) {
      hashArr[arr.links[i].source] = [];
    }
    hashArr[arr.links[i].source].push(arr.links[i]);
  }

  let result = hierarhySort(hashArr, arr.links.source, []);

  return result;
};

export const normalizeWeekday = (day: number) => {
  if (day == 0) {
    day = 6;
  } else {
    day = day - 1;
  }
  return day;
};
