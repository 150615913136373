import { PureComponent } from 'react';
import AuthenticationContext from '../../../../Authentication/types/AuthContextType';
import ReactECharts from 'echarts-for-react';
import { t } from 'i18next';
import { getColor } from '../../utils/chartComponentsUtils';
import _ from 'lodash';
import { Skeleton } from '@mui/material';
import EmptyHeatMapChartView from '../../views/EmptyHeatMapChartView';
import HeatMapChartSkeleton from '../../skeleton/HeatMapChartSkeleton';
import {
  HeatMapChartContainerProps,
  HeatMapChartContainerState,
  HeatMapChartDataPoint,
  HeatMapChartPoint,
  HeatMapChartsData,
  HeatMapRangeItem,
  responseHeatMapDataPoints,
} from '@ComponentsRoot/ChartsComponents/types/HeatMapChartTypes';

class HeatmapChartContainer extends PureComponent<HeatMapChartContainerProps, HeatMapChartContainerState> {
  static contextType = AuthenticationContext;
  constructor(props: HeatMapChartContainerProps) {
    super(props);
    this.state = {
      chartsData: false,
      dataPoints: false,
      error: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.chart && !this.state.dataPoints && !this.state.chartsData) {
      await this.getDataPoints();
    }
  };

  units = '';
  getDataPoints = async () => {
    try {
      const response = (await this.props.chartRepository.manageChartType(this.props.chart)) as responseHeatMapDataPoints;
      const dataPoints: HeatMapChartDataPoint = response?.content;
      const deviceUnits = response?.deviceUnits;
      this.units = deviceUnits && deviceUnits != '' ? deviceUnits : 'hours';

      if (!dataPoints || !dataPoints.data) {
        this.setState({
          error: {
            type: 'call',
            message: t('translation:somethingWrong'),
          },
        });
        return null;
      } else if (!dataPoints.data.length) {
        this.setState({
          error: {
            type: 'empty',
            message: t('translation:nodata'),
          },
        });
        return null;
      }

      const chartsData: HeatMapChartsData = {
        name: this.props.chart.title,
        nameReference: this.props.chart.title,
        colors: getColor(this.props.chart.title),
        data: dataPoints.data,
        reference: [],
        options: this.getHeatmapOptions(dataPoints.data),
      };

      this.setState({ dataPoints: dataPoints.data, chartsData: chartsData as HeatMapChartsData });
    } catch (error: any) {
      this.setState({
        error: {
          type: 'call',
          message: error?.message ? error?.message : error,
        },
      });
    }
  };

  getHeatmapOptions = (dataPoints: HeatMapChartPoint) => {
    const newOptions = _.cloneDeep(this.props.chart.options);
    newOptions.series[0].data = dataPoints;
    newOptions.series[0].label.show = false;
    newOptions.yAxis.name = t('charts:axisTitles.weekDays');
    newOptions.xAxis.name = t('charts:axisTitles.' + this.units.toLowerCase());
    const cosmosyAxis = t('charts:heatmap.days').split(',');
    newOptions.yAxis.data = cosmosyAxis.length === 7 ? cosmosyAxis : newOptions.yAxis.data;
    newOptions.tooltip.formatter = function (value) {
      return '' + value.data[2] + '';
    };
    delete newOptions.visualMap.calculable;
    newOptions.visualMap.pieces = this.props.chart.range
      ? this.buildHeatMapRange()
      : [
          { min: 0, max: 5, color: '#BFDFEE', label: t('charts:heatmap.legend.low_default') },
          { min: 5, max: 10, color: '#60AED4', label: t('charts:heatmap.legend.medium_default') },
          { min: 10, max: 20, color: '#007DBA', label: t('charts:heatmap.legend.high_default') },
          { min: 20, max: 150, color: '#003E5D', label: t('charts:heatmap.legend.intensive_default') },
        ];
    delete newOptions.title;
    return newOptions;
  };

  buildHeatMapRange() {
    const range = this.props.chart.range
    const updatedRange = range.map((element) => ({ 
      min: element.min,
      max: element.max,
      color: element.color,
      label: t('charts:heatmap.legend.' + element.label),
    }));
    return updatedRange;
  }

  render() {
    const { error, chartsData, dataPoints } = this.state;
    return (
      <div className='heatmap-chart-box'>
        <div className='heatmap'>
          {error ? (
            <EmptyHeatMapChartView {...error} />
          ) : chartsData ? (
            <ReactECharts style={{ height: '400px', width: '100%', margin: 0, padding: '10px' }} option={chartsData.options} />
          ) : !dataPoints ? (
            // <Skeleton animation="wave" variant='rectangular' width={1042} height={380} />
            <HeatMapChartSkeleton></HeatMapChartSkeleton>
          ) : null}
        </div>
      </div>
    );
  }
}

export default HeatmapChartContainer;
